'use client';

import { classNames } from '@/util/classNames';
import { useEffect, useRef } from 'react';
import { CarouselNavArrows } from './CarouselNavArrows';

type CarouselProps = {
  children;
  gap?: 'base' | 'small' | 'smallest';
  carouselNavArrowsClassName?: string;
  invertedNavArows?: boolean;
  arrowsInMiddle?: boolean;
  disableGradient?: boolean;
  hideArrows?: boolean;
  className?: string;
  disableSnap?: boolean;
};

export function Carousel(props: CarouselProps) {
  const {
    children,
    gap = 'base',
    carouselNavArrowsClassName,
    arrowsInMiddle,
    invertedNavArows,
    disableGradient,
    hideArrows,
    className,
    disableSnap,
  } = props;
  const carouselRef = useRef<HTMLDivElement>(null);

  return (
    <div className="relative">
      <div
        className={classNames(
          'w-full flex overflow-x-auto',
          !disableSnap && 'snap-mandatory snap-x',
          gap === 'smallest' && 'gap-2',
          gap === 'small' && 'gap-4',
          gap === 'base' && 'gap-5',
          'shadow-rightShadowInner',
          className,
        )}
        ref={carouselRef}
      >
        {children}
        {!disableGradient && (
          <div className="absolute right-0 top-0 h-full w-10 bg-gradient-to-l dark:from-darkGrey from-white via-transparent via-15% hidden sm:block" />
        )}
      </div>
      <CarouselNavArrows
        hideArrows={hideArrows}
        className={carouselNavArrowsClassName}
        scrollerRef={carouselRef}
        arrowsInMiddle={arrowsInMiddle}
        inverted={invertedNavArows}
      />
    </div>
  );
}

type CarouselItemProps = {
  children;
  width;
  smFullWidth?: boolean;
  className?: string;
};

export function CarouselItem(props: CarouselItemProps) {
  const { children, width, smFullWidth, className } = props;
  return (
    <div
      style={{ width: width }}
      className={classNames(
        'snap-center sm:snap-start shrink-0  w-[138px] rounded-xl relative text-center group',
        smFullWidth && 'sm:!w-full',
        className,
      )}
    >
      {children}
    </div>
  );
}
