'use client';
import { useEffect, useState } from 'react';

type Props = {
  pubtime: string;
  onlyTime?: boolean;
};

export function PubTime(props: Props) {
  const [pubtime, setPubtime] = useState(() => {
    return new Intl.DateTimeFormat('de-DE', {
      dateStyle: props.onlyTime ? undefined : 'short',
      timeStyle: 'short',
      timeZone: 'Europe/Berlin',
    }).format(new Date(props.pubtime));
  });

  useEffect(() => {
    const formattedPubTime = new Intl.DateTimeFormat('de-DE', {
      dateStyle: props.onlyTime ? undefined : 'short',
      timeStyle: 'short',
    }).format(new Date(props.pubtime));

    setPubtime(formattedPubTime);
  }, [props.onlyTime, props.pubtime]);

  return (
    <time dateTime={new Date(props.pubtime).toISOString()}> {pubtime}</time>
  );
}
