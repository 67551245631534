'use client';

import { usePathname, useRouter } from 'next/navigation';
import { useEffect } from 'react';

let lastTimeFetched = {};

export function Revalidator() {
  const currentRoute = usePathname();
  const router = useRouter();
  useEffect(() => {
    if (!lastTimeFetched[currentRoute]) {
      lastTimeFetched[currentRoute] = new Date();
      return;
    }

    // check if route is older than 5 Minutes then revalidate
    if (
      lastTimeFetched[currentRoute].getTime() <
      new Date().getTime() - 300000
    ) {
      router.refresh();
      lastTimeFetched[currentRoute] = new Date();
    }
  });
  return null;
}
