import { IoArrowForwardOutline } from 'react-icons/io5';
import { PFText } from './PFText';
import { ReactNode } from 'react';
import { classNames } from '@/util/classNames';

type Props = {
  variant?: 'base' | 'baseRounded' | 'nextNews' | 'invertedHover' | 'textOnly';
  asChild?: boolean;
  children: ReactNode;
  className?: string;
  arrow?: boolean;
};

export function PFButton(props: Props) {
  const {
    variant = 'base',
    asChild,
    children,
    className,
    arrow,
    ...rest
  } = props;

  const Tag = asChild ? 'span' : 'button';

  if (variant === 'nextNews') {
    return (
      <Tag
        {...rest}
        className={classNames('flex gap-2 group items-center', className)}
      >
        <PFText
          size="base"
          className={'text-primaryColor group-hover:text-secundaryColor'}
        >
          {children}
        </PFText>
        {arrow && (
          <IoArrowForwardOutline className="text-primaryColor group-hover:text-secundaryColor" />
        )}
      </Tag>
    );
  }

  return (
    <Tag
      {...rest}
      className={classNames(
        'sm:text-xl px-8 gap-1 w-full flex items-center justify-center border-none  p-3 text-lg font-semibold text-center self-center transition ease-in-out duration-300  sm:cursor-pointer',
        variant !== 'textOnly' && 'text-white bg-primaryColor ',
        variant === 'baseRounded' ? 'rounded-full' : 'rounded-xl',
        variant === 'invertedHover'
          ? 'hover:text-darkGrey hover:bg-white '
          : variant === 'textOnly'
            ? 'hover:text-tintColorHover active:text-tintColorHover'
            : 'hover:bg-tintColorHover active:bg-tintColorHover active:text-white',
        className,
      )}
    >
      {children} {arrow && <IoArrowForwardOutline className="text-white" />}
    </Tag>
  );
}
