'use client';
import { createStore, Provider } from 'jotai';
import { ReactNode, type JSX } from 'react';

export const jotaiStore = createStore();

export function JotaiProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  return <Provider store={jotaiStore}>{children}</Provider>;
}
