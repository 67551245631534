'use client';
import { mediaQueries } from '@/common/styleguide';
import { classNames } from '@/util/classNames';
import { set } from 'cypress/types/lodash';
import { Ref, RefObject, useEffect, useState } from 'react';
import { IoArrowBackOutline, IoArrowForwardOutline } from 'react-icons/io5';
import { IoChevronForward, IoChevronBack } from 'react-icons/io5';

type Props = {
  className?: string;
  scrollerRef: RefObject<HTMLDivElement | null>;
  inverted?: boolean;
  arrowsInMiddle?: boolean;
  hideArrows?: boolean;
};

export function CarouselNavArrows(props: Props) {
  const {
    className,
    scrollerRef,
    inverted,
    arrowsInMiddle,
    hideArrows = false,
  } = props;
  const [scrollPosition, setScrollPosition] = useState<
    'start' | 'middle' | 'end'
  >('start');
  const [buttonsVisible, setButtonsVisible] = useState(!hideArrows);

  useEffect(() => {
    scrollerRef.current?.addEventListener('scroll', (e) => {
      if (e.target.scrollLeft === 0) {
        setScrollPosition('start');
      } else if (
        e.target.scrollLeft + e.target.clientWidth >
        e.target.scrollWidth - 25
      ) {
        setScrollPosition('end');
      } else if (scrollPosition !== 'middle') {
        setScrollPosition('middle');
      }
    });
  }, [scrollPosition, scrollerRef]);

  useEffect(() => {
    function setVisibility() {
      if (
        scrollerRef.current?.scrollWidth &&
        scrollerRef.current?.scrollWidth <= 971 &&
        buttonsVisible
      ) {
        setButtonsVisible(false);
      }
    }

    setVisibility();

    const isDesktopMatchMedia = window.matchMedia(
      `(${mediaQueries.minWidthDesktop})`,
    );

    const onChangeDesktopMedia = () => {
      if (
        scrollerRef.current?.scrollWidth &&
        scrollerRef.current?.scrollWidth <= 971
      ) {
        scrollerRef.current?.scroll({ left: 0 });
      }
      setVisibility();
    };
    isDesktopMatchMedia.addEventListener('change', onChangeDesktopMedia);

    return () => {
      isDesktopMatchMedia.removeEventListener('change', onChangeDesktopMedia);
    };
  }, [buttonsVisible, scrollPosition, scrollerRef]);

  if (!buttonsVisible) return;

  return (
    <>
      <button
        className={classNames(
          'absolute hidden sm:flex justify-center items-center rounded-full w-11 h-11  transition-opacity group',
          inverted
            ? 'bg-white border-solid border-[1px] border-lightGrey disabled:opacity-40 enabled:hover:bg-darkPink enabled:hover:border-secundaryColor'
            : 'bg-tintColor disabled:opacity-20 enabled:hover:bg-darkPink',
          arrowsInMiddle && 'left-[415px]',
          !arrowsInMiddle && 'left-1',
          className,
        )}
        onClick={() => {
          scrollerRef.current?.scroll({
            left:
              scrollerRef?.current.scrollLeft -
              scrollerRef?.current.children[0]?.clientWidth,
            behavior: 'smooth',
          });
        }}
        disabled={scrollPosition === 'start'}
        title="Gehe nach links"
      >
        <IoChevronBack
          className={classNames(
            inverted
              ? 'text-primaryColor group-enabled:group-hover:text-white'
              : 'text-white',
            'w-6 h-6 mr-[2px]',
          )}
        />
      </button>
      <button
        className={classNames(
          'absolute hidden sm:flex justify-center items-center rounded-full w-11 h-11 disabled:opacity-20 transition-opacity group',
          inverted
            ? 'bg-white border-solid border-[1px] border-lightGrey  disabled:opacity-40 enabled:hover:bg-darkPink enabled:hover:border-secundaryColor'
            : 'bg-tintColor disabled:opacity-20 enabled:hover:bg-darkPink',
          arrowsInMiddle && 'right-[415px]',
          !arrowsInMiddle && 'right-1',
          className,
        )}
        onClick={() => {
          scrollerRef.current?.scroll({
            left:
              scrollerRef?.current.scrollLeft +
              scrollerRef?.current.children[0]?.clientWidth,
            behavior: 'smooth',
          });
        }}
        disabled={scrollPosition === 'end'}
        title="Gehe nach rechts"
      >
        <IoChevronForward
          className={classNames(
            inverted
              ? 'text-primaryColor group-enabled:group-hover:text-white'
              : 'text-white',
            'w-6 h-6 ml-[2px]',
          )}
        />
      </button>
    </>
  );
}
