import { MouseEventHandler } from 'react';
import { graphURL } from './urls';
import { pushMutation$data } from '@/__generated__/relay/pushMutation.graphql';
import { atomWithStorage } from 'jotai/utils';
import { atom, useAtom } from 'jotai';
import { jotaiStore } from '@/components/JotaiProvider';

const base64ToUint8Array = (base64: string) => {
  const padding = '='.repeat((4 - (base64.length % 4)) % 4);
  const b64 = (base64 + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(b64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

export const tagsAtom = atomWithStorage<{ tagId: string; tagName: string }[]>(
  'tags',
  [],
  undefined,
  {
    getOnInit: true,
  },
);
export const generalPushAtom = atomWithStorage<boolean>(
  'generalPush',
  false,
  undefined,
  {
    getOnInit: true,
  },
);
const subscriptionAtom = atom<PushSubscription>();
const registrationAtom = atom<ServiceWorkerRegistration>();
const registrationIdAtom = atom<string>();

if (
  typeof window !== 'undefined' &&
  'serviceWorker' in navigator &&
  window.workbox !== undefined
) {
  // run only in browser
  navigator.serviceWorker.ready.then((reg) => {
    if (!reg.pushManager) return;
    jotaiStore.set(registrationAtom, reg);
    reg.pushManager.getSubscription().then((sub) => {
      if (
        sub &&
        !(sub.expirationTime && Date.now() > sub.expirationTime - 5 * 60 * 1000)
      ) {
        jotaiStore.set(subscriptionAtom, sub);

        fetch(`${graphURL}pq/a897ada8fa49185bbda0019cbed172b5/`, {
          cache: 'no-store',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            variables: {
              input: {
                isEnabled: true,
                service: 'WEB',
                token: JSON.stringify(sub.toJSON()),
              },
            },
          }),
        })
          .then((res) => res.json())
          .then(({ data }: { data: pushMutation$data }) => {
            if (data.syncPushToken.__typename !== 'SyncPushTokenSuccess') {
              console.error('push', data);
              return;
            }
            jotaiStore.set(
              registrationIdAtom,
              data.syncPushToken.registration.id,
            );
            jotaiStore.set(
              generalPushAtom,
              data.syncPushToken.registration.isEnabled,
            );
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  });
}

export type usePushProps = {
  tagId: string;
  tagName: string;
};

export function usePush(tag?: usePushProps, toggleGeneral?: boolean) {
  const [subscription, setSubscription] = useAtom(subscriptionAtom);
  const [tags, setTags] = useAtom(tagsAtom);
  const [generalPush, setGeneralPush] = useAtom(generalPushAtom);
  const [registration, setRegistration] = useAtom(registrationAtom);

  const subscribeButtonOnClick: MouseEventHandler<HTMLButtonElement> = async (
    event,
  ) => {
    if (!subscription || toggleGeneral) {
      if (!process.env.NEXT_PUBLIC_WEB_PUSH_PUBLIC_KEY) {
        throw new Error('Environment variables supplied not sufficient.');
      }
      if (!registration) {
        console.error('No SW registration available.');
        return;
      }
      event.preventDefault();
      const sub = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: base64ToUint8Array(
          process.env.NEXT_PUBLIC_WEB_PUSH_PUBLIC_KEY,
        ),
      });
      // TODO: you should call your API to save subscription data on the server in order to send web push notification from the server
      setSubscription(sub);

      const { data }: { data: pushMutation$data } = await fetch(
        `${graphURL}pq/a897ada8fa49185bbda0019cbed172b5/`,
        {
          cache: 'no-store',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            variables: {
              input: {
                isEnabled: toggleGeneral ? !generalPush : true,
                service: 'WEB',
                token: JSON.stringify(sub.toJSON()),
              },
            },
          }),
        },
      ).then((res) => res.json());

      if (data.syncPushToken.__typename !== 'SyncPushTokenSuccess') {
        console.error('push', data);
        return;
      }
      jotaiStore.set(registrationIdAtom, data.syncPushToken.registration.id);

      if (toggleGeneral)
        setGeneralPush(data.syncPushToken.registration.isEnabled);
    }

    if (toggleGeneral || !tag) {
      return;
    }

    const unsubscribe = '7965c03e98f0e7231614dd4cb8e0eed2';
    const subscibe = '06c0b6b0fc95f372d6449a2e1b62c4a8';

    const includesTag = tags.some((tagAtom) => tagAtom.tagId === tag.tagId);
    const pq = includesTag ? unsubscribe : subscibe;

    includesTag
      ? setTags(tags.filter((tagAtom) => tagAtom.tagId !== tag.tagId))
      : setTags([...tags, tag]);

    fetch(`${graphURL}pq/${pq}/`, {
      cache: 'no-store',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        variables: {
          input: {
            registrationId: jotaiStore.get(registrationIdAtom),
            tagId: tag.tagId,
          },
        },
      }),
    })
      .then((res) => res.json())
      .then((data: pushMutation$data) => {
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return {
    isPushAviable,
    askPermission,
    subscribeButtonOnClick,
    isSubscribed: toggleGeneral
      ? generalPush
      : tags.some((tagAtom) => tagAtom.tagId === tag?.tagId),
    subscription,
  };
}

export function isPushAviable() {
  //Needed, because cypress sometimes throws error in this (probably trying something like 'serviceWorker' in undefined => Error: undefined is not an Object)
  try {
    if (!('serviceWorker' in navigator)) {
      // Service Worker isn't supported on this browser, disable or hide UI.
      return false;
    }

    if (!('PushManager' in window)) {
      // Push isn't supported on this browser, disable or hide UI.
      return false;
    }

    if (!('Notification' in window)) {
      // Check if the browser supports notifications
      return false;
    }
  } catch (error) {
    return false;
  }

  return true;
}

function askPermission() {
  if (!('Notification' in window)) {
    // Check if the browser supports notifications
    alert('This browser does not support desktop notification');
  }

  return new Promise(function (resolve, reject) {
    const permissionResult = window.Notification.requestPermission(
      function (result) {
        resolve(result);
      },
    );

    if (permissionResult) {
      permissionResult.then(resolve, reject);
    }
  }).then(function (permissionResult) {
    if (permissionResult !== 'granted') {
      alert(
        'Um Push-Benachrichtigungen zu aktivieren, musst du diese für Promiflash in deinen Browser-Einstellungen erlauben.',
      );
      throw new Error("We weren't granted permission.");
    }
  });
}
