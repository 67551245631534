'use client';

import { usePathname } from 'next/navigation';
import { useEffect } from 'react';

type Props = {
  pageType: string;
  pageTitle: string;
  other?: {};
};

let firstPageView = true;

export function PageTracking(props: Props) {
  const { pageType, pageTitle, other } = props;
  const pathname = usePathname();
  useEffect(() => {
    window.dataLayer?.push({
      event: 'page_meta',
      page_type: pageType,
      page_path: pathname,
      page_title: pageTitle,
      canonical_url: `https://www.promiflash.de${pathname}`,
      ...other,
    });

    // Marfeel tracks first pageview internally
    if (!firstPageView) {
      window.marfeel.cmd.push([
        'compass',
        function (compass) {
          compass.trackNewPage();
        },
      ]);
    } else {
      firstPageView = false;
    }
  }, [other, pathname, pageTitle, pageType]);
  return null;
}
